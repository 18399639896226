// extracted by mini-css-extract-plugin
export var pricingContainer = "pricing-module--pricing-container--3UbFU";
export var pricingContainer2 = "pricing-module--pricing-container-2--rJdIo";
export var pricingColumn = "pricing-module--pricing-column--YLe0O";
export var valueCallout = "pricing-module--value-callout--3cSyM";
export var valueCalloutWithNoValue = "pricing-module--value-callout-with-no-value--3HPmh";
export var priceTierName = "pricing-module--price-tier-name--1ZR1f";
export var price = "pricing-module--price--3RVjm";
export var perMonth = "pricing-module--per-month--oZchr";
export var plusAdSpend = "pricing-module--plus-ad-spend--2LJSx";
export var featuresList = "pricing-module--features-list--20LTO";
export var feature = "pricing-module--feature--3SX8e";
export var minimumAdSpend = "pricing-module--minimum-ad-spend--3QpPF";
export var whyMinimum = "pricing-module--why-minimum--1dsx9";